import type { AppRegion } from '@/entities/region'
import type { AuthState } from './types'
import { authStorage } from '@/shared/lib/storage'
import { queryClient } from '@/shared/lib/tanstack-query'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { create } from 'zustand'
import { authKeys } from './authKeys'

// Initial auth state
const initialState: AuthState = {
  user: null,
  token: null,
  region: null,
  projectId: null,
  identityProvider: null,
  storeCode: null,
  isAuthenticated: false,
  isLoading: true,
  error: null,
}

// Define login function types
interface SsoLoginParams {
  method: 'sso'
  idToken: string
  region: AppRegion
  projectId: string
  destinationId: string
}

interface UrlCodeLoginParams {
  method: 'url_code'
  urlCode: string
  region: AppRegion
}

type LoginParams = SsoLoginParams | UrlCodeLoginParams

// Define the auth store interface
interface AuthStore extends AuthState {
  // Actions
  initialize: () => Promise<void>
  login: (params: LoginParams) => Promise<any>
  logout: () => Promise<void>
  resetError: () => void
  setLoading: (isLoading: boolean) => void
  setError: (error: Error | null) => void
  syncWithStorage: () => void
}

/**
 * Auth store using Zustand
 * Manages authentication state and provides actions for login, logout, etc.
 */
export const useAuthStore = create<AuthStore>((set, _get) => ({
  ...initialState,

  /**
   * Initialize auth state from storage and Firebase
   */
  initialize: async () => {
    try {
      // Get stored auth data
      const storedToken = authStorage.getAuthToken()
      const storedRegion = authStorage.getRegion()
      const storedProjectId = authStorage.getSsoProjectId()
      const storedIdentityProvider = authStorage.getIdentityProvider()
      const storedStoreCode = authStorage.getStoreCode()

      // If we have a token, assume we're authenticated temporarily
      if (storedToken) {
        set({
          token: storedToken,
          region: storedRegion,
          projectId: storedProjectId || null,
          identityProvider: storedIdentityProvider || null,
          storeCode: storedStoreCode || null,
          isAuthenticated: true,
          isLoading: false,
        })
      }

      // Listen for Firebase auth state changes
      const auth = getAuth()
      // We need to keep the listener active but don't need to store the unsubscribe function
      // since Zustand doesn't have a cleanup mechanism like useEffect
      onAuthStateChanged(
        auth,
        async (user) => {
          if (user) {
            // Update auth state with Firebase user and token
            // and update storage
            try {
              const token = await user.getIdToken()

              set({
                user,
                token,
                isAuthenticated: true,
                isLoading: false,
                error: null,
              })

              authStorage.setAuthToken(token)
            } catch (error) {
              console.error('Failed to get ID token:', error)
              set({
                error: new Error('Failed to get authentication token'),
                isLoading: false,
              })
            }
          } else {
            // User is signed out
            const hasToken = !!authStorage.getAuthToken()

            set({
              user: null,
              isAuthenticated: hasToken, // Even if Firebase session expired, maintain auth state if we still have a valid backend token
              isLoading: false,
            })
          }
        },
        (error) => {
          console.error('Firebase auth state error:', error)
          set({
            error: error as Error,
            isLoading: false,
          })
        },
      )
    } catch (error) {
      console.error('Auth initialization error:', error)
      set({
        error: error as Error,
        isLoading: false,
      })
    }
  },

  /**
   * Login with SSO or URL code
   */
  login: async (params: LoginParams) => {
    set({ isLoading: true, error: null })

    try {
      if (params.method === 'sso') {
        const { idToken, region, projectId, destinationId } = params

        // Import dynamically to avoid circular dependencies
        const { getAuthTokenViaSso } = await import('@/features/auth/sso/model/api')

        // Get auth token from API using SSO ID token
        const token = await getAuthTokenViaSso(
          region,
          idToken,
          destinationId,
          projectId,
        )

        // Update storage
        authStorage.setAuthToken(token)
        authStorage.setRegion(region)
        authStorage.setSsoDetails(projectId, 'firebase') // Using 'firebase' as identity provider

        // Update state
        set({
          token,
          region,
          projectId,
          identityProvider: 'firebase',
          isAuthenticated: true,
          isLoading: false,
          error: null,
        })

        // Invalidate auth query to trigger reactivity
        queryClient.invalidateQueries({ queryKey: authKeys.current() })

        return { success: true }
      } else if (params.method === 'url_code') {
        const { urlCode, region } = params

        // Import dynamically to avoid circular dependencies
        const { getSsoUrlCodeDetails } = await import('@/features/auth/sso/model/api')

        // Get URL code details from API
        const urlCodeDetails = await getSsoUrlCodeDetails(urlCode, region)

        // Update storage
        authStorage.setRegion(region)

        // Check if urlCodeDetails has a storeCode property and it's a string
        const storeCode = 'storeCode' in urlCodeDetails
          && typeof urlCodeDetails.storeCode === 'string'
          ? urlCodeDetails.storeCode
          : null

        if (storeCode) {
          authStorage.setStoreCode(storeCode)
        }

        // Update state with what we have so far
        set({
          region,
          storeCode,
          isLoading: false,
          // Note: Not setting isAuthenticated to true yet, as we still need to complete the SSO flow
        })

        // Invalidate auth query to trigger reactivity
        queryClient.invalidateQueries({ queryKey: authKeys.current() })

        return urlCodeDetails
      }
    } catch (error) {
      console.error('Login error:', error)

      set({
        error: error as Error,
        isLoading: false,
      })

      throw error
    }
  },

  /**
   * Logout and clear auth state
   */
  logout: async () => {
    set({ isLoading: true })

    try {
      // Sign out from Firebase
      const auth = getAuth()
      await signOut(auth)

      // Clear storage
      authStorage.clearAllData()

      // Reset state
      set({
        ...initialState,
        isLoading: false,
      })

      // Invalidate auth query to trigger reactivity
      queryClient.invalidateQueries({ queryKey: authKeys.current() })
    } catch (error) {
      console.error('Logout error:', error)

      set({
        error: error as Error,
        isLoading: false,
      })
    }
  },

  /**
   * Reset error state
   */
  resetError: () => {
    set({ error: null })
  },

  /**
   * Set loading state
   */
  setLoading: (isLoading: boolean) => {
    set({ isLoading })
  },

  /**
   * Set error state
   */
  setError: (error: Error | null) => {
    set({ error })
  },

  /**
   * Sync state with storage
   */
  syncWithStorage: () => {
    const storedToken = authStorage.getAuthToken()
    const storedRegion = authStorage.getRegion()
    const storedProjectId = authStorage.getSsoProjectId()
    const storedIdentityProvider = authStorage.getIdentityProvider()
    const storedStoreCode = authStorage.getStoreCode()

    set({
      token: storedToken || null,
      region: storedRegion || null,
      projectId: storedProjectId || null,
      identityProvider: storedIdentityProvider || null,
      storeCode: storedStoreCode || null,
      isAuthenticated: !!storedToken,
    })
  },
}))

/**
 * Initialize auth on app startup
 * Call this once in your app's entry point
 */
export function initializeAuth() {
  return useAuthStore.getState().initialize()
}
