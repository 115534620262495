import type { AppRegion } from '@/shared/config/regions'
import { createPersistenceSyncError, getStorageWithExpiry, removeStorage, setStorageWithExpiry } from './utils'

// Storage keys
const KEYS = {
  AUTH_TOKEN: 'authToken',
  REGISTRATION_CODE: 'registrationCode',
  PROJECT_ID: 'ssoProjectId',
  IDENTITY_PROVIDER: 'ssoIdentityProvider',
  SSO_LOGIN_PAGE: 'ssoLoginPage',
  STORE_CODE: 'storeCode',
  REGION: 'region',
}

/**
 * Sets a value in both primary and backup storage
 * @param key - The key to store the value under
 * @param value - The value to store
 */
function setDualStorageValue(key: string, value: string): void {
  setStorageWithExpiry(key, value)
  sessionStorage.setItem(key, value) // Use sessionStorage as a backup
}

/**
 * Gets a value from storage, checking for consistency
 * @param key - The key to retrieve the value for
 * @returns The stored value or null if not found
 */
function getValueFromStorage(key: string): string | null {
  const primaryValue = getStorageWithExpiry(key)
  const backupValue = sessionStorage.getItem(key)

  // If both values exist but don't match, log an error
  if (primaryValue !== null && backupValue !== null && primaryValue !== backupValue) {
    console.error(createPersistenceSyncError(key, primaryValue, backupValue))
    // Sync the values by updating the backup
    sessionStorage.setItem(key, primaryValue)
  }

  // If primary value exists, return it
  if (primaryValue !== null) {
    return primaryValue
  }

  // If only backup value exists, restore it to primary and return it
  if (backupValue !== null) {
    setStorageWithExpiry(key, backupValue)
    return backupValue
  }

  // Neither value exists
  return null
}

/**
 * Unified authentication storage API
 */
export const authStorage = {
  // Auth token
  getAuthToken: () => getValueFromStorage(KEYS.AUTH_TOKEN),
  setAuthToken: (token: string) => setDualStorageValue(KEYS.AUTH_TOKEN, token),

  // Registration code
  getRegistrationCode: () => getValueFromStorage(KEYS.REGISTRATION_CODE),
  setRegistrationCode: (code: string) => setDualStorageValue(KEYS.REGISTRATION_CODE, code),

  // Region
  getRegion: () => getValueFromStorage(KEYS.REGION) as AppRegion | null,
  setRegion: (region: AppRegion) => setDualStorageValue(KEYS.REGION, region),

  // SSO details
  setSsoDetails: (projectId: string, identityProvider: string) => {
    setDualStorageValue(KEYS.PROJECT_ID, projectId)
    setDualStorageValue(KEYS.IDENTITY_PROVIDER, identityProvider)
  },
  getSsoProjectId: () => getValueFromStorage(KEYS.PROJECT_ID),
  getIdentityProvider: () => getValueFromStorage(KEYS.IDENTITY_PROVIDER),

  // SSO login page
  setSsoLoginPage: (url: string) => setDualStorageValue(KEYS.SSO_LOGIN_PAGE, url),
  getSsoLoginPage: () => getValueFromStorage(KEYS.SSO_LOGIN_PAGE),

  // Store code
  setStoreCode: (code: string) => setDualStorageValue(KEYS.STORE_CODE, code),
  getStoreCode: () => getValueFromStorage(KEYS.STORE_CODE),

  // Clear methods
  clearSsoData: () => {
    removeStorage(KEYS.PROJECT_ID)
    removeStorage(KEYS.IDENTITY_PROVIDER)
    removeStorage(KEYS.SSO_LOGIN_PAGE)
    sessionStorage.removeItem(KEYS.PROJECT_ID)
    sessionStorage.removeItem(KEYS.IDENTITY_PROVIDER)
    sessionStorage.removeItem(KEYS.SSO_LOGIN_PAGE)
  },

  clearAuthData: () => {
    removeStorage(KEYS.AUTH_TOKEN)
    removeStorage(KEYS.STORE_CODE)
    sessionStorage.removeItem(KEYS.AUTH_TOKEN)
    sessionStorage.removeItem(KEYS.STORE_CODE)
  },

  clearRegistrationData: () => {
    removeStorage(KEYS.REGISTRATION_CODE)
    sessionStorage.removeItem(KEYS.REGISTRATION_CODE)
  },

  clearRegion: () => {
    removeStorage(KEYS.REGION)
    sessionStorage.removeItem(KEYS.REGION)
  },

  clearAllData: () => {
    Object.values(KEYS).forEach((key) => {
      removeStorage(key)
      sessionStorage.removeItem(key)
    })
  },

  // Sync method to ensure consistency
  reSyncAuthStorage: () => {
    Object.values(KEYS).forEach((key) => {
      const primaryValue = getStorageWithExpiry(key)
      const backupValue = sessionStorage.getItem(key)

      if (primaryValue !== null && backupValue === null) {
        // Restore backup
        sessionStorage.setItem(key, primaryValue)
      } else if (primaryValue === null && backupValue !== null) {
        // Restore primary
        setStorageWithExpiry(key, backupValue)
      } else if (primaryValue !== null && backupValue !== null && primaryValue !== backupValue) {
        // Values don't match, use primary as source of truth
        sessionStorage.setItem(key, primaryValue)
      }
    })
  },
}
