import type { AuthState } from '../model/types'
import { redirect } from '@tanstack/react-router'
import { useAuthStore } from '../model/store'

/**
 * Auth guard for protected routes
 * Used in route loaders to check if user is authenticated
 * Redirects to login page if not authenticated
 */
export async function requireAuth(): Promise<AuthState> {
  try {
    // Get auth state from Zustand store
    const authState = useAuthStore.getState()

    // If the store is still loading, wait for it to finish
    if (authState.isLoading) {
      // Initialize auth if it hasn't been initialized yet
      if (!authState.user && !authState.token) {
        await useAuthStore.getState().initialize()
      }

      // Wait a bit for the auth state to settle
      await new Promise(resolve => setTimeout(resolve, 100))
    }

    // Check if user is authenticated
    if (!authState.isAuthenticated) {
      // Redirect to login page with return URL
      throw redirect({
        to: '/login',
        search: {
          from: window.location.pathname,
        },
      })
    }

    // User is authenticated, allow access
    return authState
  } catch (error) {
    // If error is a redirect, throw it
    if (error instanceof Error && 'to' in error) {
      throw error
    }

    // Otherwise, redirect to login
    console.error('Auth check failed:', error)
    throw redirect({
      to: '/login',
      search: {
        from: window.location.pathname,
      },
    })
  }
}

/**
 * Store code guard for routes that require a store code
 * Used after requireAuth to ensure user has a store code
 * Redirects to store connection page if no store code
 */
export async function requireStoreCode(): Promise<AuthState> {
  try {
    // First ensure user is authenticated
    const authData = await requireAuth()

    // Check if user has a store code
    if (!authData.storeCode) {
      // Redirect to store connection page
      throw redirect({
        to: '/',
        search: {
          from: window.location.pathname,
        },
      })
    }

    // User has a store code, allow access
    return authData
  } catch (error) {
    // If error is a redirect, throw it
    if (error instanceof Error && 'to' in error) {
      throw error
    }

    // Otherwise, redirect to login
    console.error('Store code check failed:', error)
    throw redirect({
      to: '/login',
    })
  }
}

/**
 * Public only guard for routes that should only be accessible to unauthenticated users
 * Redirects to home page if user is authenticated
 */
export async function publicOnlyGuard(): Promise<void> {
  try {
    // Get auth state from Zustand store
    const authState = useAuthStore.getState()

    // If the store is still loading, wait for it to finish
    if (authState.isLoading) {
      // Initialize auth if it hasn't been initialized yet
      if (!authState.user && !authState.token) {
        await useAuthStore.getState().initialize()
      }

      // Wait a bit for the auth state to settle
      await new Promise(resolve => setTimeout(resolve, 100))
    }

    // Check if user is authenticated
    if (authState.isAuthenticated) {
      // Redirect to home page
      throw redirect({
        to: '/',
      })
    }
  } catch (error) {
    // If error is a redirect, throw it
    if (error instanceof Error && 'to' in error) {
      throw error
    }

    // Otherwise, rethrow
    throw error
  }
}
