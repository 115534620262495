/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../../routes/__root'
import { Route as SigninImport } from './../../routes/_signin'
import { Route as DashboardImport } from './../../routes/_dashboard'
import { Route as IndexImport } from './../../routes/index'
import { Route as SigninLoginImport } from './../../routes/_signin/login'
import { Route as DashboardDashboardIndexImport } from './../../routes/_dashboard/dashboard.index'
import { Route as DashboardDashboardOverdueImport } from './../../routes/_dashboard/dashboard.overdue'
import { Route as DashboardDashboardHistoryImport } from './../../routes/_dashboard/dashboard.history'
import { Route as SigninRegionSsoUrlCodeImport } from './../../routes/_signin/$region.sso.$urlCode'

// Create/Update Routes

const SigninRoute = SigninImport.update({
  id: '/_signin',
  getParentRoute: () => rootRoute,
} as any)

const DashboardRoute = DashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SigninLoginRoute = SigninLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => SigninRoute,
} as any)

const DashboardDashboardIndexRoute = DashboardDashboardIndexImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardDashboardOverdueRoute = DashboardDashboardOverdueImport.update({
  id: '/dashboard/overdue',
  path: '/dashboard/overdue',
  getParentRoute: () => DashboardRoute,
} as any)

const DashboardDashboardHistoryRoute = DashboardDashboardHistoryImport.update({
  id: '/dashboard/history',
  path: '/dashboard/history',
  getParentRoute: () => DashboardRoute,
} as any)

const SigninRegionSsoUrlCodeRoute = SigninRegionSsoUrlCodeImport.update({
  id: '/$region/sso/$urlCode',
  path: '/$region/sso/$urlCode',
  getParentRoute: () => SigninRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof DashboardImport
      parentRoute: typeof rootRoute
    }
    '/_signin': {
      id: '/_signin'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof SigninImport
      parentRoute: typeof rootRoute
    }
    '/_signin/login': {
      id: '/_signin/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof SigninLoginImport
      parentRoute: typeof SigninImport
    }
    '/_dashboard/dashboard/history': {
      id: '/_dashboard/dashboard/history'
      path: '/dashboard/history'
      fullPath: '/dashboard/history'
      preLoaderRoute: typeof DashboardDashboardHistoryImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/dashboard/overdue': {
      id: '/_dashboard/dashboard/overdue'
      path: '/dashboard/overdue'
      fullPath: '/dashboard/overdue'
      preLoaderRoute: typeof DashboardDashboardOverdueImport
      parentRoute: typeof DashboardImport
    }
    '/_dashboard/dashboard/': {
      id: '/_dashboard/dashboard/'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof DashboardDashboardIndexImport
      parentRoute: typeof DashboardImport
    }
    '/_signin/$region/sso/$urlCode': {
      id: '/_signin/$region/sso/$urlCode'
      path: '/$region/sso/$urlCode'
      fullPath: '/$region/sso/$urlCode'
      preLoaderRoute: typeof SigninRegionSsoUrlCodeImport
      parentRoute: typeof SigninImport
    }
  }
}

// Create and export the route tree

interface DashboardRouteChildren {
  DashboardDashboardHistoryRoute: typeof DashboardDashboardHistoryRoute
  DashboardDashboardOverdueRoute: typeof DashboardDashboardOverdueRoute
  DashboardDashboardIndexRoute: typeof DashboardDashboardIndexRoute
}

const DashboardRouteChildren: DashboardRouteChildren = {
  DashboardDashboardHistoryRoute: DashboardDashboardHistoryRoute,
  DashboardDashboardOverdueRoute: DashboardDashboardOverdueRoute,
  DashboardDashboardIndexRoute: DashboardDashboardIndexRoute,
}

const DashboardRouteWithChildren = DashboardRoute._addFileChildren(
  DashboardRouteChildren,
)

interface SigninRouteChildren {
  SigninLoginRoute: typeof SigninLoginRoute
  SigninRegionSsoUrlCodeRoute: typeof SigninRegionSsoUrlCodeRoute
}

const SigninRouteChildren: SigninRouteChildren = {
  SigninLoginRoute: SigninLoginRoute,
  SigninRegionSsoUrlCodeRoute: SigninRegionSsoUrlCodeRoute,
}

const SigninRouteWithChildren =
  SigninRoute._addFileChildren(SigninRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof SigninRouteWithChildren
  '/login': typeof SigninLoginRoute
  '/dashboard/history': typeof DashboardDashboardHistoryRoute
  '/dashboard/overdue': typeof DashboardDashboardOverdueRoute
  '/dashboard': typeof DashboardDashboardIndexRoute
  '/$region/sso/$urlCode': typeof SigninRegionSsoUrlCodeRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof SigninRouteWithChildren
  '/login': typeof SigninLoginRoute
  '/dashboard/history': typeof DashboardDashboardHistoryRoute
  '/dashboard/overdue': typeof DashboardDashboardOverdueRoute
  '/dashboard': typeof DashboardDashboardIndexRoute
  '/$region/sso/$urlCode': typeof SigninRegionSsoUrlCodeRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_dashboard': typeof DashboardRouteWithChildren
  '/_signin': typeof SigninRouteWithChildren
  '/_signin/login': typeof SigninLoginRoute
  '/_dashboard/dashboard/history': typeof DashboardDashboardHistoryRoute
  '/_dashboard/dashboard/overdue': typeof DashboardDashboardOverdueRoute
  '/_dashboard/dashboard/': typeof DashboardDashboardIndexRoute
  '/_signin/$region/sso/$urlCode': typeof SigninRegionSsoUrlCodeRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/login'
    | '/dashboard/history'
    | '/dashboard/overdue'
    | '/dashboard'
    | '/$region/sso/$urlCode'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/login'
    | '/dashboard/history'
    | '/dashboard/overdue'
    | '/dashboard'
    | '/$region/sso/$urlCode'
  id:
    | '__root__'
    | '/'
    | '/_dashboard'
    | '/_signin'
    | '/_signin/login'
    | '/_dashboard/dashboard/history'
    | '/_dashboard/dashboard/overdue'
    | '/_dashboard/dashboard/'
    | '/_signin/$region/sso/$urlCode'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DashboardRoute: typeof DashboardRouteWithChildren
  SigninRoute: typeof SigninRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DashboardRoute: DashboardRouteWithChildren,
  SigninRoute: SigninRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_dashboard",
        "/_signin"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_dashboard": {
      "filePath": "_dashboard.tsx",
      "children": [
        "/_dashboard/dashboard/history",
        "/_dashboard/dashboard/overdue",
        "/_dashboard/dashboard/"
      ]
    },
    "/_signin": {
      "filePath": "_signin.tsx",
      "children": [
        "/_signin/login",
        "/_signin/$region/sso/$urlCode"
      ]
    },
    "/_signin/login": {
      "filePath": "_signin/login.tsx",
      "parent": "/_signin"
    },
    "/_dashboard/dashboard/history": {
      "filePath": "_dashboard/dashboard.history.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/dashboard/overdue": {
      "filePath": "_dashboard/dashboard.overdue.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/dashboard/": {
      "filePath": "_dashboard/dashboard.index.tsx",
      "parent": "/_dashboard"
    },
    "/_signin/$region/sso/$urlCode": {
      "filePath": "_signin/$region.sso.$urlCode.tsx",
      "parent": "/_signin"
    }
  }
}
ROUTE_MANIFEST_END */
