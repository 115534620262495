import type { MantineColorsTuple } from '@mantine/core'

export const bluedotPrimaryColors: MantineColorsTuple = [
  '#f7eaff',
  '#e7d1ff',
  '#cba0fc',
  '#ad6bf8',
  '#953ff5',
  '#8523f4',
  '#7d14f4',
  '#6b08da',
  '#5f04c3',
  '#5100ac',
]
