import type { AppRegion } from '@/entities/region'
import type { AuthState } from '../model/types'
import { authStorage } from '@/shared/lib/storage'
import { queryClient } from '@/shared/lib/tanstack-query'
import {
  browserLocalPersistence,
  getAuth,
  indexedDBLocalPersistence,
  inMemoryPersistence,
  setPersistence,
} from 'firebase/auth'
import { authKeys } from '../model/authKeys'

// Use a simplified version of AuthState for local operations
type AuthStateInput = Partial<{
  token: string | null
  region: AppRegion | null
  projectId: string | null
  identityProvider: string | null
  storeCode: string | null
}>

/**
 * Unified service for managing authentication state across Firebase and local storage
 */
export const authService = {
  /**
   * Initialize Firebase auth persistence
   * Attempts to use browser persistence first, then falls back to indexedDB, then in-memory
   */
  async initialize(): Promise<void> {
    const auth = getAuth()

    try {
      // Set Firebase persistence
      await setPersistence(auth, browserLocalPersistence)

      // Sync authStorage to ensure consistency
      authStorage.reSyncAuthStorage()
    } catch (error) {
      console.error('Error setting persistence:', error)
      // Fallback to indexedDB persistence if browser persistence fails
      try {
        await setPersistence(auth, indexedDBLocalPersistence)
      } catch (fallbackError) {
        console.error('Error setting fallback persistence:', fallbackError)

        // Last resort: in-memory persistence
        try {
          await setPersistence(auth, inMemoryPersistence)
        } catch (lastError) {
          console.error('Failed to set any persistence method:', lastError)
        }
      }
    }
  },

  /**
   * Get current auth state from both Firebase and local storage
   */
  getAuthState(): AuthState {
    return {
      user: null, // Firebase user will be added separately
      token: authStorage.getAuthToken() || null,
      region: authStorage.getRegion() || null,
      projectId: authStorage.getSsoProjectId() || null,
      identityProvider: authStorage.getIdentityProvider() || null,
      storeCode: authStorage.getStoreCode() || null,
      isAuthenticated: !!authStorage.getAuthToken(),
      isLoading: false,
      error: null,
    }
  },

  /**
   * Set auth state in both Firebase and local storage
   */
  setAuthState(state: AuthStateInput): void {
    // Update local storage
    if (state.token) {
      authStorage.setAuthToken(state.token)
    }

    if (state.region) {
      authStorage.setRegion(state.region)
    }

    if (state.projectId && state.identityProvider) {
      authStorage.setSsoDetails(state.projectId, state.identityProvider)
    }

    if (state.storeCode) {
      authStorage.setStoreCode(state.storeCode)
    }

    // Invalidate auth query to trigger reactivity
    queryClient.invalidateQueries({ queryKey: authKeys.current() })
  },

  /**
   * Set SSO login page for potential recovery
   */
  setSsoLoginPage(url: string): void {
    authStorage.setSsoLoginPage(url)
  },

  /**
   * Get SSO login page
   * @d
   */
  getSsoLoginPage(): string | null {
    return authStorage.getSsoLoginPage()
  },

  /**
   * Clear auth state from both Firebase and local storage
   */
  async clearAuthState(): Promise<void> {
    const auth = getAuth()

    // Sign out from Firebase
    await auth.signOut()

    // Clear local storage
    authStorage.clearAllData()

    // Invalidate auth query to trigger reactivity
    queryClient.invalidateQueries({ queryKey: authKeys.current() })
  },

  /**
   * Force sync storage from localStorage to memory
   */
  syncStorage(): void {
    authStorage.reSyncAuthStorage()
  },
}
