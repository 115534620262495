import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/dashboard/history')({
  component: HistoryComponent,
})

function HistoryComponent() {
  return (
    <div>
      <h1>History</h1>
      <p>
        This is the history page.
      </p>
    </div>
  )
}
