import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/dashboard/overdue')({
  component: OverdueComponent,
})

function OverdueComponent() {
  return (
    <div>
      <h1>Overdue</h1>
      <p>
        This is the overdue orders page.
      </p>
    </div>
  )
}
