import { publicOnlyGuard } from '@/features/auth/lib/guards'
import { AuthLayout } from '@/features/auth/ui'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_signin')({
  component: RouteComponent,
  // Use the public-only guard to prevent authenticated users from accessing this route
  beforeLoad: () => {
    return publicOnlyGuard()
  },
})

function RouteComponent() {
  return (
    <AuthLayout />
  )
}
