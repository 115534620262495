import type {
  Auth,
  UserCredential,
} from 'firebase/auth'
import { authService } from '@/features/auth/lib/authService'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  getRedirectResult,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'

const firebaseConfig = {
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_GCP_PROJECT,
  storageBucket: `${import.meta.env.VITE_GCP_PROJECT}.appspot.com`,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
}

// Initialize Firebase app
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)

// Initialize auth service with proper persistence
authService.initialize()

/**
 * @description Initiates SSO login with the specified identity provider
 */
export async function initiateSSOPopup(identityProvider: string) {
  try {
    const provider = new SAMLAuthProvider(`saml.${identityProvider}`)
    // Store the identity provider in localStorage for persistence across redirects
    localStorage.setItem('sso_provider_id', identityProvider)

    return await signInWithPopup(auth, provider)
  } catch (error) {
    console.error('Error setting up SSO provider:', error)
  }
}

// TODO: Address sign on with redirect
// User object is not available after redirect
// returns null instead of UserCredential

/**
 * @description Initiates SSO login with the specified identity provider
 * @deprecated Not deprecated. Requires additional work to implement
 */
export function initiateSSORedirect(identityProvider: string): void {
  try {
    const provider = new SAMLAuthProvider(`saml.${identityProvider}`)
    // Store the identity provider in localStorage for persistence across redirects
    localStorage.setItem('sso_provider_id', identityProvider)

    signInWithRedirect(auth, provider)
  } catch (error) {
    console.error('Error setting up SSO provider:', error)
  }
}

/**
 * @description Gets the result of the redirect sign-in
 * @deprecated Not deprecated. Requires additional work to implement
 */
export async function getSsoRedirectResult(): Promise<UserCredential | null> {
  try {
    console.log('Auth state before redirect result:', auth)

    // Ensure auth service is synced before checking redirect result
    authService.syncStorage()

    const result = await getRedirectResult(auth)
    console.log('Redirect result received:', result)
    return result
  } catch (error) {
    console.error('Error getting redirect result:', error)
    return null
  }
}

/**
 * @description Gets the current Firebase auth instance
 */
export function getFirebaseAuth(): Auth {
  return auth
}
