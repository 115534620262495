import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { reportWebVitals } from '~/shared/lib/web-vitals'
import { Provider } from './providers'
import '@/features/auth/sso/lib/firebase'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (import.meta.env.VITE_ENABLE_MOCKS === 'true') {
  import('@/shared/lib/test/mocks/browser')
    .then(async ({ worker }) => {
      const started = await worker.start()

      // Make it available in the console for debugging
      window.msw = { worker }

      if (started) {
        console.warn('MSW initialized. Use window.msw.worker in console to debug')
      } else {
        console.error('MSW failed to initialize. Mocks will not be available.')
      }
    })
    .then(() => {
      root.render(
        <StrictMode>
          <Provider />
        </StrictMode>,
      )
    })
} else {
  root.render(
    <StrictMode>
      <Provider />
    </StrictMode>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
