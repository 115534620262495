import { mockOrdersBoard } from '@/shared/lib/test/mocks/order/mockOrderDetails'
import { OrdersBoard } from '@/widgets/orders-board'
import { DEFAULT_COLUMNS } from '@/widgets/orders-board/orders-board.config'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/dashboard/')({
  component: HelloScreensDashboard,
})

function HelloScreensDashboard() {
  return (
    <OrdersBoard orders={mockOrdersBoard} columns={DEFAULT_COLUMNS} />
  )
}
