import type { ReactNode } from 'react'
import { UnheadProvider as _UnheadProvider, createHead } from '@unhead/react/client'

interface UnheadProviderProps {
  children: ReactNode
}

export function UnheadProvider({ children }: UnheadProviderProps) {
  const head = createHead()

  return (
    <_UnheadProvider head={head}>
      {children}
    </_UnheadProvider>
  )
}
