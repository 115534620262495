import { Box, Button, Container, Group, Stack, Text, Title } from '@mantine/core'
import { IconArrowRight, IconCloudLockOpen, IconDashboard, IconHome } from '@tabler/icons-react'
import { createFileRoute, Link } from '@tanstack/react-router'

export const Route = createFileRoute('/')({
  component: App,
})

function App() {
  return (
    <Box
      style={{
        background: 'linear-gradient(135deg, var(--mantine-color-bluedot-primary-0) 0%, var(--mantine-color-bluedot-primary-1) 100%)',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 'var(--mantine-spacing-xl)',
      }}
    >
      <Container size="md">
        <Stack gap="xl" align="center">
          <Box
            style={{
              background: 'var(--mantine-color-white)',
              padding: 'var(--mantine-spacing-xl)',
              borderRadius: 'var(--mantine-radius-lg)',
              boxShadow: 'var(--mantine-shadow-md)',
              width: '100%',
              maxWidth: '800px',
            }}
          >
            <Stack gap="lg" align="center">
              <Title
                order={1}
                ta="center"
                c="bluedot-primary.6"
                fw={700}
                style={{ fontSize: '2.5rem' }}
              >
                Welcome to Hello Screens
              </Title>

              <Text size="lg" c="dimmed" ta="center" maw={600} mx="auto">
                A powerful platform for managing and displaying content across your screens.
                Navigate to the dashboard to get started.
              </Text>

              <Group mt="xl" justify="center">
                <Button
                  component={Link}
                  to="/dashboard"
                  size="lg"
                  variant="filled"
                  color="bluedot-primary.6"
                  rightSection={<IconArrowRight size={18} />}
                >
                  Go to Dashboard
                </Button>
              </Group>
            </Stack>
          </Box>

          <Group justify="center" p="xl" mt="md">
            <Link
              to="/"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: 'var(--mantine-color-bluedot-primary-9)',
                fontWeight: 600,
                textDecoration: 'none',
              }}
              activeProps={{
                style: {
                  fontWeight: 700,
                  textDecoration: 'underline',
                },
              }}
              activeOptions={{ exact: true }}
            >
              <IconHome size={18} />
              Home
            </Link>

            <Link
              to="/dashboard"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: 'var(--mantine-color-bluedot-primary-9)',
                fontWeight: 600,
                textDecoration: 'none',
              }}
              activeProps={{
                style: {
                  fontWeight: 700,
                  textDecoration: 'underline',
                },
              }}
            >
              <IconDashboard size={18} />
              Dashboard
            </Link>

            <Link
              to="/login"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: 'var(--mantine-color-bluedot-primary-9)',
                fontWeight: 600,
                textDecoration: 'none',
              }}
              activeProps={{
                style: {
                  fontWeight: 700,
                  textDecoration: 'underline',
                },
              }}
            >
              <IconCloudLockOpen size={18} />
              SSO Connect
            </Link>

            <Link
              to="/$region/sso/$urlCode"
              params={{ region: 'au', urlCode: 'coles' }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                color: 'var(--mantine-color-bluedot-primary-9)',
                fontWeight: 600,
                textDecoration: 'none',
              }}
              activeProps={{
                style: {
                  fontWeight: 700,
                  textDecoration: 'underline',
                },
              }}
            >
              <IconCloudLockOpen size={18} />
              SSO Register
            </Link>

          </Group>
        </Stack>
      </Container>
    </Box>
  )
}
