import { SsoAuthentication } from '@/features/auth/sso'
import { createFileRoute } from '@tanstack/react-router'
import { useHead } from '@unhead/react'

export const Route = createFileRoute('/_signin/$region/sso/$urlCode')({
  component: SsoConnectComponent,
})

function SsoConnectComponent() {
  useHead({
    title: 'Hello Screens - SSO',
    meta: [
      { name: 'description', content: 'Connect with Hello Screens' },
    ],
  })

  return (
    <SsoAuthentication />
  )
}
