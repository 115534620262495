import { Button, createTheme, Drawer, Select } from '@mantine/core'
import { IconX } from '@tabler/icons-react'
import { bluedotPrimaryColors } from './colors'

export const theme = createTheme({
  fontFamily: 'Open Sans, sans-serif',
  primaryColor: 'bluedot-primary',
  primaryShade: 6,
  colors: {
    'bluedot-primary': bluedotPrimaryColors,
  },
  components: {
    Button: Button.extend({
      defaultProps: {
        variant: 'outline',
      },
      styles: theme => ({
        root: {
          borderRadius: theme.radius.xl,
          borderWidth: '3px',
          fontWeight: 700,
        },
      }),
    }),
    Drawer: Drawer.extend({
      defaultProps: {
        closeButtonProps: {
          icon: <IconX size={40} stroke={1.5} />,
        },
      },
    }),
    Select: Select.extend({
      defaultProps: {
        variant: 'filled',
        size: 'lg',
      },
      styles: () => ({
        input: {
          fontSize: '15px',
        },
        option: {
          fontSize: '15px',
        },
      }),
    }),
  },
})
