import { OneTimeCodeGeneration } from '@/features/auth/oneTimeCode'
import { createFileRoute } from '@tanstack/react-router'
import { useHead } from '@unhead/react'

export const Route = createFileRoute('/_signin/login')({
  component: OneTimeCodeGenerationComponent,
})

function OneTimeCodeGenerationComponent() {
  useHead({
    title: 'Hello Screens - One Time Code',
    meta: [
      { name: 'description', content: 'One Time Code with Hello Screens' },
    ],
  })

  return (
    <OneTimeCodeGeneration />
  )
}
