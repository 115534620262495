// Constants
export const STORAGE_EXPIRY_DAYS = 365

/**
 * Sets a value in localStorage with expiration
 * @param key - The key to store the value under
 * @param value - The value to store
 * @param days - Number of days until expiration
 */
export function setStorageWithExpiry(key: string, value: string, days: number = STORAGE_EXPIRY_DAYS): void {
  const expiryMs = days * 24 * 60 * 60 * 1000
  const item = {
    value,
    expiry: new Date().getTime() + expiryMs,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

/**
 * Gets a value from localStorage, checking expiration
 * @param key - The key to retrieve the value for
 * @returns The stored value or null if not found or expired
 */
export function getStorageWithExpiry(key: string): string | null {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }

  try {
    const item = JSON.parse(itemStr)
    const now = new Date().getTime()

    // Check if the item is expired
    if (now > item.expiry) {
      localStorage.removeItem(key)
      return null
    }

    return item.value
  } catch {
    // If the item is not in the expected format, return it as is
    // This handles backward compatibility with existing data
    return itemStr
  }
}

/**
 * Removes a value from localStorage
 * @param key - The key to remove
 */
export function removeStorage(key: string): void {
  localStorage.removeItem(key)
}

/**
 * Creates an error message for persistence sync issues
 * @param key - The key with sync issues
 * @param primaryValue - The primary storage value
 * @param backupValue - The backup storage value
 * @returns Formatted error message
 */
export function createPersistenceSyncError(key: string, primaryValue: string, backupValue: string): string {
  return `Persistence sync error for ${key}: primary value (${primaryValue}) doesn't match backup value (${backupValue})`
}

/**
 * Gets a numeric value from storage
 * @param key - The key to retrieve the value for
 * @param defaultValue - Default value if not found
 * @returns The numeric value or default
 */
export function getNumericValue(key: string, defaultValue: number = 0): number {
  const value = getStorageWithExpiry(key)
  if (value === null) {
    return defaultValue
  }

  const numericValue = Number.parseInt(value, 10)
  return Number.isNaN(numericValue) ? defaultValue : numericValue
}
