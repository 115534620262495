import { requireAuth } from '@/features/auth/lib/guards'
import { DashboardShell } from '@/widgets/dashboard-layout'
import { createFileRoute, Outlet } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard')({
  component: RouteComponent,
  beforeLoad: () => {
    return requireAuth()
  },
})

function RouteComponent() {
  return (
    <DashboardShell>
      <Outlet />
    </DashboardShell>
  )
}
