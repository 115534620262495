import { createRootRoute, Outlet } from '@tanstack/react-router'

export const Route = createRootRoute({
  component: RootComponent,
})

/**
 * Root component that provides the router outlet
 */
function RootComponent() {
  return <Outlet />
}
