import { MantineProvider } from './MantineProvider.tsx'
import { QueryClientProvider } from './QueryClientProvider.tsx'
import { RouterProvider } from './RouterProvider.tsx'
import { UnheadProvider } from './UnheadProvider.tsx'

export function Provider() {
  return (
    <>
      <UnheadProvider>
        <QueryClientProvider>
          <MantineProvider>
            <RouterProvider />
          </MantineProvider>
        </QueryClientProvider>
      </UnheadProvider>
    </>
  )
}
