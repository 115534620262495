import type { ReactNode } from 'react'
import { theme } from '@/shared/lib/mantine'
import { MantineProvider as _MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'

interface MantineProviderProps {
  children: ReactNode
}

export function MantineProvider({ children }: MantineProviderProps) {
  return (
    <_MantineProvider
      theme={theme}
      defaultColorScheme="light"
    >
      {children}
    </_MantineProvider>
  )
}
