import type { ReactNode } from 'react'
import { queryClient } from '@/shared/lib/tanstack-query'
import {

  QueryClientProvider as TanStackQueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

interface QueryClientProviderProps {
  children: ReactNode
}

export function QueryClientProvider({ children }: QueryClientProviderProps) {
  return (
    <TanStackQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools
        initialIsOpen={false}
      />
    </TanStackQueryClientProvider>
  )
}
