import { initializeAuth, useAuthStore } from '@/features/auth/model/store'
import { createRouter, RouterProvider as TanstackRouterProvider } from '@tanstack/react-router'
import { useEffect } from 'react'
import { queryClient } from '~/shared/lib/tanstack-query'
import { routeTree } from './routeTree.gen.ts'

const router = createRouter({
  routeTree,
  context: {
    queryClient,
    get auth() {
      const authState = useAuthStore.getState()
      return {
        isAuthenticated: authState.isAuthenticated,
        isLoading: authState.isLoading,
        token: authState.token,
        region: authState.region,
        storeCode: authState.storeCode,
        projectId: authState.projectId,
      }
    },
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  scrollRestoration: true,
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export function RouterProvider() {
  useEffect(() => {
    initializeAuth()
  }, [])

  return (
    <TanstackRouterProvider router={router} />
  )
}
